<template>
	<div class="page-content page-container chat-container" id="chat-container">
		<div class="card card-bordered">
			<div class="card-header">
				<h4 class="card-title">
					<strong>
						Отправьте нам сообщение!
					</strong>
					<button class="show-chat bg-transparent" aria-label="Close">
						<i class="fa-regular fa-xmark"></i>
					</button>
				</h4>
			</div>
			<div class="ps-container ps-theme-default ps-active-y" id="chat-content"
				style="overflow-y: scroll !important; height:400px !important;">
				<chat-messages :hi_message="hi_message" :messages="messages"></chat-messages>
				<div class="ps-scrollbar-x-rail" style="left: 0px; bottom: 0px;">
					<div class="ps-scrollbar-x" tabindex="0" style="left: 0px; width: 0px;"></div>
				</div>
				<div class="ps-scrollbar-y-rail" style="top: 0px; height: 0px; right: 2px;">
					<div class="ps-scrollbar-y" tabindex="0" style="top: 0px; height: 2px;"></div>
				</div>
			</div>
			<chat-form :typing="typing" v-on:messagesent="addMessage"></chat-form>
		</div>
	</div>
</template>
<script>
export default {
	props: ["messages", "typing", "hi_message"],
	methods: {
		addMessage(message) {
			var dt = new Date();
			var minutes = dt.getMinutes();
			var time = dt.getHours() + ":" + (minutes < 10 ? '0' : '') + minutes;
			var month = dt.getMonth() + 1;
			var day = dt.getDate();
			var date = dt.getFullYear() + '-' +
				(month < 10 ? '0' : '') + month + '-' +
				(day < 10 ? '0' : '') + day;
			message.time = time;
			this.messages[date].push(message);
			$('.ps-container').scroll();
			$('.ps-container').animate({ scrollTop: $('.ps-container')[0].scrollHeight }, 1000);
			message.user_id = $('#user-id').val();
			message.user_type = 'user';
			//http://127.0.0.1:8000
			axios.post(apiUrl + '/api/messages', message).then(() => {
				axios.post(apiUrl + '/api/notification', { message: message.message });
			})
		}
	},
};
</script>