<template>
	<div class="media media-meta-day text-center"></div>
	<div v-if="helloText != ''" class="media media-chat media-chat-admin">
		<img class="avatar" src="https://img.icons8.com/color/36/000000/administrator-male.png" alt="...">
		<div class="media-body from-them">
			<p class="imessage from-them">
				{{ helloText }} {{ hi_message }}
			</p>
			<p class="meta"><time :datetime="time">{{ time }}</time></p>
		</div>
	</div>
	<template v-for="(dates, indexD) in messages">
		<div class="media media-meta-day text-center">{{ indexD }}</div>
		<div v-for="messages in dates" class="media media-chat"
			:class="{ 'media-chat-reverse': messages.type == 'user', 'media-chat-admin': messages.type == 'admin' }">
			<img v-if="messages.type == 'admin'" class="avatar"
				src="https://img.icons8.com/color/36/000000/administrator-male.png" alt="...">
			<div class="media-body" :class="{ 'from-them': messages.type == 'admin', 'from-me': messages.type == 'user' }">
				<p class="imessage" :class="{ 'from-them': messages.type == 'admin', 'from-me': messages.type == 'user' }">
					{{ messages.message }}</p>
				<p class="meta"><time datetime="{{ indexD }}">{{ messages.time
				}}</time></p>
			</div>
		</div>
	</template>
</template>
<script>
export default {
	data() {
		return {
			helloText: "",
			time: ""
		}
	},
	props: ["messages", "hi_message"],
	created() {
		var dt = new Date();
		var minutes = dt.getMinutes();
		var hours = dt.getHours();
		this.time = hours + ":" + (minutes < 10 ? '0' : '') + minutes;
		if (hours >= 5 && hours < 12) {
			this.helloText = "Доброе утро!";
		} else if (hours >= 12 && hours < 17) {
			this.helloText = "Добрый день!";
		} else if (hours >= 17 && hours < 22) {
			this.helloText = "Добрый вечер!";
		} else {
			this.helloText = "Доброй ночи!";
		}
	}
};
</script>
