//import 'bootstrap';

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.apiUrl = "";
if (window.location.origin === 'http://127.0.0.1:8002') {
	window.apiUrl = 'http://127.0.0.1:8000';
} else {
	window.apiUrl = 'https://xn--80a4af.xn--p1ai';
}
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
window.Pusher = Pusher;

window.Echo = new Echo({
	broadcaster: 'pusher',
	key: '9faa41c919888842dbee',
	cluster: 'eu',
});
