<template>
	<span :class="{ 'active': typing == true }" id="istyping">Специалист печатает...</span>
	<div class="scroll-bottom"><i class="fa-light fa-angle-down"></i></div>
	<div class="publisher bt-1 border-light">

		<input @focus="setTyping(true)" autocapitalize="off" autocomplete="off" autocorrect="off"
			@blur="setTyping(false)" class="publisher-input m-0" type="text" placeholder="Введите сообщение..."
			name="message" v-model="newMessage" @keyup.enter="sendMessage">
		<button aria-label="Send" class="publisher-btn text-info" id="btn-chat" @click="sendMessage" data-abc="true"><i
				class="fa fa-paper-plane"></i></button>
	</div>
</template>
<script>
export default {
	props: ["typing"],
	emits: ["messagesent"],
	data() {
		return {
			newMessage: "",
		};
	},
	methods: {
		sendMessage() {
			if (this.newMessage.length > 0 && this.newMessage != "") {
				this.$emit("messagesent", {
					message: this.newMessage,
					type: 'user'
				});
				//Clear the input
				this.newMessage = "";
			}
		},
		setTyping(data) {
			//http://127.0.0.1:8000
			axios.post(apiUrl + '/api/typing', { typing: data });
		}
	},
};
</script>
